import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Draft from "../components/Draft";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Draft />
  </Layout>
);

export default IndexPage;
