import React from "react";
import styled from "styled-components";

const PlayersLayout = styled.div``;

const PlayersGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  .column {
    display: grid;
    gap: 10px;
    border: 1px solid grey;
    padding: 5px;
  }
  .players {
    display: grid;
    grid-template-columns: 1fr;
  }
  .teams {
    display: grid;
    grid-auto-flow: column;
  }
  .team {
    cursor: pointer;
  }
`;

export default function Players({ players, draftPlayer }) {
  return (
    <PlayersLayout>
      <PlayersGrid>
        <div className="column">
          <div className={"players"}>Name</div>
          {Object.keys(players).length > 0 &&
            Object.keys(players).map(p => {
              const player = players[p];
              if (player.team === "none") {
                return (
                  <div key={p} className={"players"}>
                    <div>{player["name"]}</div>
                  </div>
                );
              }
            })}
        </div>
        <div className="column">
          <div className={"players"}>Rank</div>
          {Object.keys(players).length > 0 &&
            Object.keys(players).map(p => {
              const player = players[p];
              if (player.team === "none") {
                return (
                  <div key={p} className={"players"}>
                    <div>{player["rank"]}</div>
                  </div>
                );
              }
            })}
        </div>
        <div className="column">
          <div className={"players"}>Roles</div>
          {Object.keys(players).length > 0 &&
            Object.keys(players).map(p => {
              const player = players[p];
              if (player.team === "none") {
                return (
                  <div key={p} className={"players"}>
                    <div>{player["role"]}</div>
                  </div>
                );
              }
            })}
        </div>
        <div className="column">
          <div className={"players"}>Team</div>
          {Object.keys(players).length > 0 &&
            Object.keys(players).map(p => {
              const player = players[p];
              if (player.team === "none") {
                return (
                  <div key={p} className="teams">
                    <div
                      type="button"
                      className="team"
                      onClick={e => draftPlayer(p, "blue")}
                    >
                      🟦
                    </div>
                    <div
                      type="button"
                      className="team"
                      onClick={e => draftPlayer(p, "red")}
                    >
                      🟥
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </PlayersGrid>
    </PlayersLayout>
  );
}
