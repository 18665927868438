import React, { useState, useEffect } from "react";
import { Chat } from "twitch-js";
import styled from "styled-components";
import Players from "./Players";
import Team from "./Team";

// Provide your token, username and channel. You can generate a token here:
// https://twitchtokengenerator.com
// https://twitchapps.com/tmi/
const username = "bustin";
const channel = "bustin";
const token = "oauth:divc0o4b1m2cuva564bd81jv4zjva7";

// const getToken = async (twitchData, setTwitchData) => {
//   const body = {};

//   const res = await fetch(`/.netlify/functions/auth`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(body),
//   });
//   const text = JSON.parse(await res.text());

//   setTwitchData({
//     ...twitchData,
//     token: text.access_token,
//     expire: text.expires_in,
//     loggedIn: true,
//   });
// };

const DraftLayout = styled.div`
  position: relative;
`;

const TeamInfo = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
`;

export default function Draft() {
  const [players, setPlayers] = useState({});
  const [twitchData, setTwitchData] = useState({
    token: "",
    expire: 0,
    loggedIn: true,
  });

  const joinChat = async () => {
    const chat = new Chat({
      username,
      token,
    });

    await chat.connect();
    await chat.join(channel);

    chat.on(`PRIVMSG`, message => {
      if (
        message.message.includes("!join") &&
        message.message.split(" - ").length === 3
      ) {
        const data = message.message.split("!join ")[1].split(" - ");

        setPlayers(p => {
          if (p.hasOwnProperty(message.username)) {
            return p;
          } else {
            return {
              ...p,
              [message.username]: {
                name: data[0],
                rank: data[1],
                role: data[2],
                team: "none",
              },
            };
          }
        });
      }
    });
  };

  useEffect(() => {
    joinChat();
  }, []);

  const draftPlayer = (playerName, team) => {
    setPlayers(s => ({ ...s, [playerName]: { ...s[playerName], team: team } }));
  };

  const undraftPlayer = playerName => {
    setPlayers(s => ({
      ...s,
      [playerName]: { ...s[playerName], team: "none" },
    }));
  };

  return (
    <DraftLayout>
      {/* {!twitchData.loggedIn && (
        <button
          type="button"
          onClick={e => getToken(twitchData, setTwitchData)}
        >
          Log in
        </button>
      )} */}
      {twitchData.loggedIn && (
        <>
          <TeamInfo>
            <Players {...{ players, draftPlayer }} />
            <Team side={"blue"} {...{ players, undraftPlayer }} />
            <Team side={"red"} {...{ players, undraftPlayer }} />
          </TeamInfo>
          <button
            style={{
              position: `absolute`,
              top: 0,
              left: -200,
            }}
            type="button"
            onClick={e => setPlayers({})}
          >
            Reset
          </button>
        </>
      )}
    </DraftLayout>
  );
}
